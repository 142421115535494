import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from 'react-i18next'
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { TransitionLink } from "gatsby-plugin-transition-link/components/TransitionLink"
import { ExternalLink } from "../components/utils/mdxComponents"
import { MDXProvider } from "@mdx-js/react"
import { localizeString } from "../components/utils/localization"
import { MDXRenderer } from "gatsby-plugin-mdx"
import * as styles from "../styles/single-project.module.scss"


function ProjectPage({data: {thisProject, projects}, children}) {
    const { t, i18n } = useTranslation();
    const {title, slug, longdescription, link, client, year, categorieslong, isImageOnly, videos, videoIsTitle, largeImages, smallImages, titleIMG, titleIMGALT} = thisProject.frontmatter;
    const nodes = projects.nodes;
    const projectlinks = nodes.map((n) => n.childMdx.frontmatter.slug);
    const thisIndex = projectlinks.indexOf(slug);
    const nextSlug = thisIndex < (projectlinks.length - 1)? projectlinks[thisIndex+1] : projectlinks[0];
    const previousSlug = thisIndex > 0? projectlinks[thisIndex-1] : projectlinks[projectlinks.length-1];


    const ProjectLargeImage = props => {
      const imageExists = largeImages[props.index] === null? false : true;
      if (!imageExists)
      {
        console.log("Warning: Image doesn't exist.")
        return <></>;
      }
      return <GatsbyImage image={getImage(largeImages[props.index])} alt={props.alt} className={[styles.portfolioImg, styles.fullWidth].join(" ")}></GatsbyImage>
    }

    const ProjectSmallImages = props => {
      const imageExists = smallImages[props.index1] === null || smallImages[props.index2] === null? false : true;
      if (!imageExists)
      {
        console.log("Warning: Image doesn't exist.")
        return <></>;
      }
      return (
        <div className={styles.gallery}>
        <GatsbyImage image={getImage(smallImages[props.index1])} className={[styles.portfolioImg, styles.fullWidth].join(" ")} alt={props.alt1}></GatsbyImage>
        <GatsbyImage image={getImage(smallImages[props.index2])} className={[styles.portfolioImg, styles.fullWidth].join(" ")} alt={props.alt2}></GatsbyImage>
        </div>  
      )
    }

    const ProjectMixed = props => {
      const imageExists = smallImages[props.imageIndex] === null || videos[props.vidIndex] === null? false : true;
      if (!imageExists)
      {
        console.log("Warning: Image doesn't exist.")
        return <></>;
      }

      if(props.videoFirst){
        return (
          <div className={styles.gallery}>
          <video className={[styles.portfolioVideo, styles.fullWidth].join(" ")} preload='auto' loop autoPlay muted>
        <source src={videos[props.vidIndex].publicURL} type="video/mp4" />
        </video>
        <GatsbyImage image={getImage(smallImages[props.imageIndex])} className={[styles.portfolioImg, styles.fullWidth].join(" ")} alt={props.imageAlt}></GatsbyImage>
        </div>  
        )
      }
      return (
        <div className={styles.gallery}>
        <GatsbyImage image={getImage(smallImages[props.imageIndex])} className={[styles.portfolioImg, styles.fullWidth].join(" ")} alt={props.imageAlt}></GatsbyImage>
        <video className={[styles.portfolioVideo, styles.fullWidth].join(" ")} preload='auto' loop autoPlay muted>
        <source src={videos[props.vidIndex].publicURL} type="video/mp4" />
        </video>
        </div>  
      )
    }
    
    const ProjectVideo = props => {
      const imageExists = videos[props.index] === null? false : true;
      if (!imageExists)
      {
        console.log("Warning: Image doesn't exist.")
        return <></>;
      }
      return (
        <video className={[styles.portfolioVideo, styles.fullWidth].join(" ")} preload='auto' loop autoPlay muted>
        <source src={videos[props.index].publicURL} type="video/mp4" />
       </video>
      )
    }



    const components = {
      a: ExternalLink,
      ProjectLargeImage,
      ProjectSmallImages,
      ProjectVideo,
      ProjectMixed
    }


    //ScrollMotion Value

//THIS IS THE PAGE FOR GALLERY-ONLY PROJECTS
//Note: This has its own CSS Module: gallery-projectpage.module.scss
if (isImageOnly){
  return(
    <Layout type = "template" isDark={false} isMobile={false} key={i18n.language}>
   <div className = "project-page">
    <section className="subpage-title-section portfolio-title-section" id="project-title-section">
        <div className="dark heading">
            <h1 className="subpage-title">
              <span id="project-title">{title}</span></h1>
        </div>
        
    </section>
    <section className="project-wrapper">
    {videoIsTitle?
            <ProjectVideo index={0}></ProjectVideo>
            :
            <GatsbyImage image={getImage(titleIMG)} alt={titleIMGALT} className={[styles.portfolioImg, styles.fullWidth].join(" ")}></GatsbyImage>
            }
      <div className="project-paragraph full-width dark">
    <MDXProvider components={components}>
    <MDXRenderer>
      {thisProject.body}
      </MDXRenderer>
    </MDXProvider>
    </div>
    </section>
    <section className="post-navigation dark" id="project-navigation">
    <div className="post-nav flex">
                  <Link to={"/projects/" + previousSlug}><span>{t("previous")}</span><span className="desktop-only">&nbsp;{t("project")}</span></Link>
                  <TransitionLink exit={{length:1}} entry={{delay: 1}} to="/projects"><span>{t("back")}</span><span className="desktop-only">&nbsp;{t("toallprojects")}</span></TransitionLink>
                  <Link to={"/projects/" + nextSlug}><span>{t("next")}</span><span className="desktop-only">&nbsp;{t("project")}</span></Link>
                </div>
    </section>
    </div> 


</Layout>
  )
}

//THIS IS THE PAGE FOR NORMAL PROJECTS
    return (
        <Layout type = "work" isDark={false} isMobile={false} key={i18n.language}>
           <div className = "project-page">
            <section className="subpage-title-section portfolio-title-section" id="project-title-section">
                <div className="dark heading">
                    <h1 className="subpage-title">
                      <span id="project-title">{title}</span></h1>
                </div>
                
            </section>
            <section className="project-wrapper">
              {videoIsTitle?
            <ProjectVideo index={0}></ProjectVideo>
            :
            <GatsbyImage image={getImage(titleIMG)} alt={titleIMGALT} className={[styles.portfolioImg, styles.fullWidth].join(" ")}></GatsbyImage>
            }
            <div className="project-info">
                <div className="project-info-col flex dark" id="project-info-col-1">
                  <div className="project-info-block">
                    <h5>{t('client')}</h5>
                    <p>{client}</p>
                  </div>
                  <div className="project-info-block">
                    <h5>Services</h5>
                   {categorieslong === null? <></>
                   : categorieslong.map(category =>
                    <p key={category}>{category}</p>
                    )
                  }
                  </div>
                  <div className="project-info-block">
                    <h5>{t('year')}</h5>
                    <p>{year === null? "" : year}</p>
                  </div>
                </div>
                <div className="project-info-col dark" id="project-info-col-2">
                  <p>
                  {longdescription}
                  </p>
                  {link===null? 
                  <></>
                  :
                  <>
                  <br/>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {t("seeitlive")}
                  </a>
                  </>
                  }
                 </div>
              </div>
              <div className="project-paragraph full-width dark">
            <MDXProvider components={components}>
            <MDXRenderer>
      {thisProject.body}
      </MDXRenderer>
                </MDXProvider>
                </div>
            </section>
            <section className="post-navigation dark" id="project-navigation">
            <div className="post-nav flex">
                  <Link to={localizeString("/projects/") + previousSlug}><span>{t("previous")}</span><span className="desktop-only">&nbsp;{t("project")}</span></Link>
                  <Link to={localizeString("/projects/")}><span>{t("back")}</span><span className="desktop-only">&nbsp;{t("toallprojects")}</span></Link>
                  <Link to={localizeString("/projects/") + nextSlug}><span>{t("next")}</span><span className="desktop-only">&nbsp;{t("project")}</span></Link>
                </div>
            </section>
            </div> 


        </Layout>
    )
}

export const query = graphql`
query ($locale: String, $slug: String) {
  thisProject: mdx(
    fields: { locale: { eq: $locale } }
    frontmatter: {slug: {eq: $slug}}) {
      body
    frontmatter {
      slug
      title
      shortdescription
      longdescription
      categorieslong
      client
      link
      year
      titleIMGALT
      thumbALT
      titleIMG {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      thumb {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      largeImages {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      smallImages {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      isImageOnly
      videos {
        publicURL
      }
      videoIsTitle
    }
  }
  projects: allFile(
    filter: {
      sourceInstanceName: { eq: "projects"}
      childMdx: { fields: { locale: { eq: $locale }}}
    },
    sort: {fields: childrenMdx___frontmatter___order, order: DESC})
    {
      nodes {
        childMdx{
        id
        frontmatter {
          slug
        }
        }
      }
    }
}



`
  
  export default ProjectPage
  
  export const Head = ({data}) => (
    <Seo title={data.thisProject.frontmatter.title} description={data.thisProject.frontmatter.shortdescription} titleDE={data.thisProject.frontmatter.title} descriptionDE={data.thisProject.frontmatter.shortdescription} image={getSrc(data.thisProject.frontmatter.titleIMG)}/>
    )